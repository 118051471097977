var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container--tablet container container--md-50" },
    [
      _vm.isAllowedToViewDialog
        ? _c("AllowedToView", {
            attrs: { report: _vm.reportModel, editor: "deskundige_simple" },
            on: { handleAlert: _vm.allowedToView }
          })
        : _vm._e(),
      _vm.isAllowedToView
        ? [
            _c(
              "v-layout",
              { staticClass: "simpleContainer", attrs: { wrap: "", row: "" } },
              [
                _c(
                  "v-layout",
                  { staticClass: "border-right" },
                  [
                    _vm.hasRejectedInfo()
                      ? _c(
                          "v-flex",
                          {
                            staticClass: "damageStatusContainer rejected",
                            attrs: { xs12: "" }
                          },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _vm.reportModel.reject_reasons &&
                                _vm.reportModel.reject_reasons.length
                                  ? _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm4: "" } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "damageStatusInfo" },
                                          [
                                            _c("span", [
                                              _vm._v("Afkeurreden(en):")
                                            ]),
                                            _c(
                                              "ul",
                                              _vm._l(
                                                _vm.reportModel.reject_reasons,
                                                function(rejectReason, index) {
                                                  return _c(
                                                    "li",
                                                    { key: index },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            rejectReason.name
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.reportModel.reject_comment &&
                                _vm.reportModel.reject_comment.length
                                  ? _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm8: "" } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "damageStatusInfo" },
                                          [
                                            _c("span", [
                                              _vm._v("Toelichting:")
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.reportModel.reject_comment
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.reportModel.internal_reject_comment &&
                                _vm.reportModel.internal_reject_comment.length
                                  ? _c(
                                      "v-flex",
                                      { attrs: { xs12: "", lg8: "" } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "damageStatusInfo" },
                                          [
                                            _c("span", [
                                              _vm._v("Toelichting intern:")
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.reportModel
                                                    .internal_reject_comment
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c("v-flex", { attrs: { xs12: "" } }, [
                  _c("span", { staticClass: "elementSubTitle" }, [
                    _vm._v("Rapport gegevens")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "elementPanel elementPanel--spaced pb-0 pt-0"
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", md4: "" } },
                            [
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "generalInfoBlock",
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          class: {
                                            editing: _vm.isEditingReport
                                          },
                                          attrs: { row: "", wrap: "" }
                                        },
                                        [
                                          !_vm.isEditingReport
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "iconContainer"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "iconContainer__edit"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "edit-icon",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.isEditingReport = true
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("edit")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.isEditingReport
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "iconContainer"
                                                },
                                                [
                                                  !_vm.isUpdatingReport
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "cancel-icon",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.isEditingReport = false
                                                              _vm.resetReport()
                                                            }
                                                          }
                                                        },
                                                        [_vm._v(" cancel ")]
                                                      )
                                                    : _vm._e(),
                                                  !_vm.isUpdatingReport
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "save-icon",
                                                          class: {
                                                            disabled:
                                                              _vm.isDisabledEditing
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.updateReport
                                                          }
                                                        },
                                                        [_vm._v(" save ")]
                                                      )
                                                    : _vm._e(),
                                                  _vm.isUpdatingReport
                                                    ? _c("LoaderCard", {
                                                        staticClass:
                                                          "spinner--inline",
                                                        class: {
                                                          checked: _vm.isSaved
                                                        },
                                                        attrs: {
                                                          type: "spinner--small"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        [
                                          !_vm.isEditingReport
                                            ? [
                                                _c(
                                                  "v-layout",
                                                  {
                                                    attrs: {
                                                      row: "",
                                                      "mb-2": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm6: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Dossiernummer:"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm6: "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.reportModel
                                                                .case_number
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  {
                                                    attrs: {
                                                      row: "",
                                                      "mb-2": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm6: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Naam deskundige:"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm6: "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.reportModel
                                                                .expert
                                                                ? _vm
                                                                    .reportModel
                                                                    .expert.name
                                                                : ""
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  {
                                                    attrs: {
                                                      row: "",
                                                      "mb-2": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm6: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Geplande datum:"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm6: "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "dateFormatTime"
                                                              )(
                                                                _vm.reportModel
                                                                  .planned_at
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        [
                                                          _vm.isFutureDate
                                                            ? _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "error-text"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Gelieve de datum & tijd aan te passen naar het verleden"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  {
                                                    attrs: {
                                                      row: "",
                                                      "mb-2": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm6: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Naam aanvrager:"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm.reportModel.applicant
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn",
                                                            attrs: { sm6: "" }
                                                          },
                                                          [
                                                            _c("p", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .reportModel
                                                                    .applicant
                                                                    .name || ""
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  {
                                                    attrs: {
                                                      row: "",
                                                      "mb-2": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm6: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [_vm._v("Adres:")]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm6: "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.reportModel
                                                                .address
                                                                ? _vm
                                                                    .reportModel
                                                                    .address
                                                                    .street
                                                                : ""
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.reportModel
                                                                .address
                                                                ? _vm
                                                                    .reportModel
                                                                    .address
                                                                    .number
                                                                : ""
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.reportModel
                                                                .address
                                                                ? _vm
                                                                    .reportModel
                                                                    .address
                                                                    .number_add
                                                                : ""
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.reportModel
                                                                .address
                                                                ? _vm
                                                                    .reportModel
                                                                    .address
                                                                    .postcode
                                                                : ""
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.reportModel
                                                                .address
                                                                ? _vm
                                                                    .reportModel
                                                                    .address
                                                                    .city
                                                                : ""
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            : _vm._e(),
                                          _vm.isEditingReport
                                            ? _c(
                                                "v-layout",
                                                {
                                                  attrs: { row: "", wrap: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        sm6: ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        {
                                                          attrs: {
                                                            row: "",
                                                            wrap: ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                sm12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-menu",
                                                                {
                                                                  ref: "dialog",
                                                                  attrs: {
                                                                    "close-on-content-click": false,
                                                                    lazy: "",
                                                                    "nudge-right":
                                                                      "100",
                                                                    "full-width":
                                                                      "",
                                                                    "max-width":
                                                                      "290px",
                                                                    "min-width":
                                                                      "290px"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      staticClass:
                                                                        "input input-date",
                                                                      attrs: {
                                                                        slot:
                                                                          "activator",
                                                                        placeholder:
                                                                          "DD-MM-YYYY",
                                                                        label:
                                                                          "Gepland",
                                                                        clearable:
                                                                          "",
                                                                        "error-messages":
                                                                          _vm
                                                                            .plannedAt
                                                                            .errorMessage
                                                                      },
                                                                      on: {
                                                                        "click:clear": function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.plannedAt.clear()
                                                                        }
                                                                      },
                                                                      slot:
                                                                        "activator",
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .plannedAt
                                                                            .formattedDate,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.plannedAt,
                                                                            "formattedDate",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "plannedAt.formattedDate"
                                                                      }
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "v-date-picker",
                                                                    {
                                                                      ref:
                                                                        "picker",
                                                                      attrs: {
                                                                        "first-day-of-week":
                                                                          "1",
                                                                        locale:
                                                                          "nl-nl",
                                                                        min:
                                                                          "1910-01-01",
                                                                        color:
                                                                          "#837f16"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .plannedAt
                                                                            .selectedDate,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.plannedAt,
                                                                            "selectedDate",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "plannedAt.selectedDate"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                sm12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  staticClass:
                                                                    "input input-time",
                                                                  attrs: {
                                                                    mask:
                                                                      _vm.mask
                                                                        .time,
                                                                    placeholder:
                                                                      "HH:MM",
                                                                    label:
                                                                      "Geplande tijd",
                                                                    clearable:
                                                                      "",
                                                                    "error-messages":
                                                                      _vm
                                                                        .plannedAt
                                                                        .errorMessageTime
                                                                  },
                                                                  on: {
                                                                    "click:clear": function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.plannedAt.clearTime()
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .plannedAt
                                                                        .selectedTime,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.plannedAt,
                                                                        "selectedTime",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "plannedAt.selectedTime"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          [
                                                            _vm.isFutureDate
                                                              ? _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs12: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "error-text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Gelieve de datum & tijd aan te passen naar het verleden"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          _vm.isEditingReport
                                                            ? _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    sm12: "",
                                                                    "mt-4": ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm.isEditingReport
                                                                    ? _c(
                                                                        "v-autocomplete",
                                                                        {
                                                                          attrs: {
                                                                            items:
                                                                              _vm.experts,
                                                                            "search-input":
                                                                              _vm.expertSearch,
                                                                            label:
                                                                              "Naam expert",
                                                                            "item-text":
                                                                              "name",
                                                                            "item-value":
                                                                              "uuid",
                                                                            "hide-details":
                                                                              "",
                                                                            disabled: !_vm.isEditingReport
                                                                          },
                                                                          on: {
                                                                            "update:searchInput": function(
                                                                              $event
                                                                            ) {
                                                                              _vm.expertSearch = $event
                                                                            },
                                                                            "update:search-input": function(
                                                                              $event
                                                                            ) {
                                                                              _vm.expertSearch = $event
                                                                            },
                                                                            keyup:
                                                                              _vm.debouncedSearch
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.currentExpert,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.currentExpert = $$v
                                                                            },
                                                                            expression:
                                                                              "currentExpert"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        2
                                      ),
                                      _c("div", { staticClass: "mt-4" }, [
                                        _vm.googleMaps && _vm.googleMaps.length
                                          ? _c("iframe", {
                                              staticStyle: { border: "0" },
                                              attrs: {
                                                width: "100%",
                                                height: "400px",
                                                frameborder: "0",
                                                src:
                                                  "https://www.google.com/maps/embed/v1/place?key=AIzaSyD9Sc8IeggEfrqhwDvY5h8Dt3b-w0aomQo&q=" +
                                                  _vm.googleMaps,
                                                allowfullscreen: ""
                                              }
                                            })
                                          : _vm._e()
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", md8: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "", "fill-height": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticStyle: { position: "relative" },
                                      attrs: { "pb-0": "" }
                                    },
                                    [
                                      _c(
                                        "v-tabs",
                                        {
                                          attrs: {
                                            "slider-color": "secondary"
                                          },
                                          model: {
                                            value: _vm.activeTabReport,
                                            callback: function($$v) {
                                              _vm.activeTabReport = $$v
                                            },
                                            expression: "activeTabReport"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-tab",
                                            {
                                              staticClass: "tab__filled",
                                              class: {
                                                active:
                                                  _vm.activeTabReport ===
                                                  "tab-bijlage"
                                              },
                                              attrs: { href: "#tab-bijlage" }
                                            },
                                            [_vm._v(" Bijlagen ")]
                                          ),
                                          _c(
                                            "v-tab",
                                            {
                                              staticClass: "tab__filled",
                                              class: {
                                                active:
                                                  _vm.activeTabReport ===
                                                  "tab-aanzichtfotos"
                                              },
                                              attrs: {
                                                href: "#tab-aanzichtfotos"
                                              }
                                            },
                                            [_vm._v(" Aanzichtfoto's ")]
                                          ),
                                          _c(
                                            "v-tab",
                                            {
                                              staticClass: "tab__filled",
                                              class: {
                                                active:
                                                  _vm.activeTabReport ===
                                                  "tab-plattegronden"
                                              },
                                              attrs: {
                                                href: "#tab-plattegronden"
                                              }
                                            },
                                            [_vm._v(" Plattegronden ")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-tabs-items",
                                        {
                                          model: {
                                            value: _vm.activeTabReport,
                                            callback: function($$v) {
                                              _vm.activeTabReport = $$v
                                            },
                                            expression: "activeTabReport"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-tab-item",
                                            {
                                              attrs: {
                                                transition: false,
                                                "reverse-transition": false,
                                                lazy: true,
                                                value: "tab-bijlage"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "elementPanel pr-0"
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    { attrs: { wrap: "" } },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          attrs: {
                                                            xs12: "",
                                                            "text-xs-right": ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "MiButton",
                                                            {
                                                              attrs: {
                                                                icon: "add",
                                                                small: "true",
                                                                color:
                                                                  "secondary"
                                                              },
                                                              nativeOn: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openReportFileDialog(
                                                                    "attachment"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Bijlagen toevoegen "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm.attachments &&
                                                  _vm.attachments.length
                                                    ? _c(
                                                        "v-layout",
                                                        {
                                                          staticClass:
                                                            "item__list pb-3",
                                                          attrs: { wrap: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "label",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        x3: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Bijlage"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs3: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "omschrijving"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Acties"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._l(
                                                            _vm.attachments,
                                                            function(
                                                              attachment,
                                                              i
                                                            ) {
                                                              return _c(
                                                                "v-flex",
                                                                {
                                                                  key: i,
                                                                  staticClass:
                                                                    "list__item",
                                                                  attrs: {
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-layout",
                                                                    {
                                                                      attrs: {
                                                                        wrap:
                                                                          "",
                                                                        "align-center":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs: {
                                                                            x3:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "item__name item__link"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "a",
                                                                                {
                                                                                  attrs: {
                                                                                    href:
                                                                                      attachment.original,
                                                                                    target:
                                                                                      "_blank"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "concatenateString"
                                                                                        )(
                                                                                          attachment.file_name
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs: {
                                                                            xs3:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  attachment.description
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs: {
                                                                            xs2:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "item__icon item__icon--action"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "a",
                                                                                {
                                                                                  attrs: {
                                                                                    href:
                                                                                      attachment.original,
                                                                                    target:
                                                                                      "_blank"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "cloud_download"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "item__icon item__icon--action"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editReportFile(
                                                                                        attachment
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "edit"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "item__icon item__icon--action delete"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deleteReportFile(
                                                                                        attachment
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "delete"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    : _c(
                                                        "v-layout",
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "fadedText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Er zijn geen bijlagen"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-tab-item",
                                            {
                                              attrs: {
                                                transition: false,
                                                "reverse-transition": false,
                                                lazy: true,
                                                value: "tab-aanzichtfotos"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "elementPanel pr-0"
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    { attrs: { wrap: "" } },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          attrs: {
                                                            xs12: "",
                                                            "text-xs-right": ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "MiButton",
                                                            {
                                                              attrs: {
                                                                icon: "add",
                                                                small: "true",
                                                                color:
                                                                  "secondary"
                                                              },
                                                              nativeOn: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openReportFileDialog(
                                                                    "perspective"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Aanzichtfoto toevoegen "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm.perspectivePictures &&
                                                  _vm.perspectivePictures.length
                                                    ? [
                                                        _c(
                                                          "draggable",
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "list-group",
                                                              on: {
                                                                start: function(
                                                                  $event
                                                                ) {
                                                                  _vm.drag = true
                                                                },
                                                                end: function(
                                                                  $event
                                                                ) {
                                                                  ;(_vm.drag = false),
                                                                    _vm.updateReportFileOrder(
                                                                      "perspectivePictures"
                                                                    )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.perspectivePictures,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.perspectivePictures = $$v
                                                                },
                                                                expression:
                                                                  "perspectivePictures"
                                                              }
                                                            },
                                                            "draggable",
                                                            _vm.dragOptions,
                                                            false
                                                          ),
                                                          [
                                                            _c(
                                                              "transition-group",
                                                              _vm._l(
                                                                _vm.perspectivePictures,
                                                                function(
                                                                  perspectivePicture,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key:
                                                                        "perspectivePictures-" +
                                                                        index,
                                                                      staticClass:
                                                                        "mediaContainerWithDescription"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "mediaContainer"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "mediaContainer__actions"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "action"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "sortIcon"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "drag_indicator"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "a",
                                                                                {
                                                                                  attrs: {
                                                                                    href:
                                                                                      perspectivePicture.original,
                                                                                    target:
                                                                                      "_blank"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "action"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "remove_red_eye"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "action",
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editReportFile(
                                                                                        perspectivePicture
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "edit"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "action",
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deleteReportFile(
                                                                                        perspectivePicture
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "delete"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "delete"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              attrs: {
                                                                                src:
                                                                                  perspectivePicture.thumb
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "mediaContainer__name"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              perspectivePicture.description
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    : _c(
                                                        "v-layout",
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "fadedText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Er zijn geen aanzichtfoto's"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                ],
                                                2
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-tab-item",
                                            {
                                              attrs: {
                                                transition: false,
                                                "reverse-transition": false,
                                                lazy: true,
                                                value: "tab-plattegronden"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "elementPanel pr-0"
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    { attrs: { wrap: "" } },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          attrs: {
                                                            xs12: "",
                                                            "text-xs-right": ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "MiButton",
                                                            {
                                                              attrs: {
                                                                icon: "add",
                                                                small: "true",
                                                                color:
                                                                  "secondary"
                                                              },
                                                              nativeOn: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openReportFileDialog(
                                                                    "maps"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Plattegrond toevoegen "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm.mapPictures &&
                                                  _vm.mapPictures.length
                                                    ? [
                                                        _c(
                                                          "draggable",
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "list-group",
                                                              on: {
                                                                start: function(
                                                                  $event
                                                                ) {
                                                                  _vm.drag = true
                                                                },
                                                                end: function(
                                                                  $event
                                                                ) {
                                                                  ;(_vm.drag = false),
                                                                    _vm.updateReportFileOrder(
                                                                      "mapPictures"
                                                                    )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.mapPictures,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.mapPictures = $$v
                                                                },
                                                                expression:
                                                                  "mapPictures"
                                                              }
                                                            },
                                                            "draggable",
                                                            _vm.dragOptions,
                                                            false
                                                          ),
                                                          [
                                                            _c(
                                                              "transition-group",
                                                              _vm._l(
                                                                _vm.mapPictures,
                                                                function(
                                                                  mapPicture,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key:
                                                                        "mapPictures-" +
                                                                        index,
                                                                      staticClass:
                                                                        "mediaContainerWithDescription"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "mediaContainer"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "mediaContainer__actions"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "action"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "sortIcon"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "drag_indicator"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "a",
                                                                                {
                                                                                  attrs: {
                                                                                    href:
                                                                                      mapPicture.original,
                                                                                    target:
                                                                                      "_blank"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "action"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "remove_red_eye"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "action",
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editReportFile(
                                                                                        mapPicture
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "edit"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "action",
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deleteReportFile(
                                                                                        mapPicture
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "delete"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "delete"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              attrs: {
                                                                                src:
                                                                                  mapPicture.thumb
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "mediaContainer__name"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              mapPicture.description
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    : _c(
                                                        "v-layout",
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "fadedText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Er zijn geen plattegronden"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("v-flex", { attrs: { xs12: "" } }, [
                  _c("span", { staticClass: "elementSubTitle" }, [
                    _vm._v("Rapport schades")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "elementPanel elementPanel--damageTabs elementPanel__sub"
                    },
                    [
                      _c(
                        "v-layout",
                        { staticClass: "damageContainer" },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "damageContainer__list",
                              attrs: { xs12: "" }
                            },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "align-content-between",
                                  attrs: { wrap: "", "fill-height": "" }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _vm.damages && _vm.damages.length
                                        ? _c(
                                            "v-layout",
                                            {
                                              staticClass:
                                                "item__list item__list--overflow",
                                              attrs: { wrap: "" }
                                            },
                                            _vm._l(_vm.damages, function(
                                              damage,
                                              index
                                            ) {
                                              return _c(
                                                "v-flex",
                                                {
                                                  key: damage.uuid,
                                                  staticClass: "list__item",
                                                  class: [
                                                    {
                                                      active:
                                                        (_vm.currentDamage
                                                          ? _vm.currentDamage
                                                              .uuid
                                                          : "") === damage.uuid
                                                    },
                                                    {
                                                      accepted:
                                                        damage.status ===
                                                        "accepted"
                                                    },
                                                    {
                                                      rejected:
                                                        damage.status ===
                                                        "rejected"
                                                    },
                                                    {
                                                      changed:
                                                        damage.status ===
                                                        "changed"
                                                    }
                                                  ],
                                                  attrs: { xs12: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.getDamage(
                                                        damage
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      staticClass: "damage",
                                                      attrs: {
                                                        wrap: "",
                                                        "align-center": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "damage__description",
                                                          attrs: { xs11: "" }
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  index + 1
                                                                ) +
                                                                ". " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "concatenateString"
                                                                  )(
                                                                    damage.name.replace(
                                                                      /\s/g,
                                                                      ""
                                                                    ).length
                                                                      ? damage.name
                                                                      : "Schade " +
                                                                          (index +
                                                                            1),
                                                                    30
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "text-xs-right damage__icon damage__icon--delete",
                                                          attrs: { xs1: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "inline-block"
                                                            },
                                                            [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              _vm._g(
                                                                                {
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deleteDamage(
                                                                                        damage
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "delete"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Verwijder schade"
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _vm.damages && _vm.damages.length === 0
                                        ? _c(
                                            "v-layout",
                                            { attrs: { "pa-3": "" } },
                                            [
                                              _c("v-flex", [
                                                _c("p", [
                                                  _vm._v(
                                                    "Klik op de + voeg schade toe om een schade aan te maken."
                                                  )
                                                ]),
                                                _c("p", [
                                                  _vm._v(
                                                    "Geen schade beschikbaar"
                                                  )
                                                ])
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "addDamage" },
                                        [
                                          _c(
                                            "MiButton",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                icon: "add_circle",
                                                fullWidth: "true"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.addDamage($event)
                                                }
                                              }
                                            },
                                            [_vm._v(" Voeg schade toe ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm.reportId &&
                              _vm.currentDamage &&
                              (_vm.currentDamage.reject_reasons.length ||
                                _vm.currentDamage.reject_comment.length)
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "damageStatusContainer",
                                      class: _vm.currentDamage.status
                                        ? _vm.currentDamage.status
                                        : ""
                                    },
                                    [
                                      _vm.currentDamage.status === "accepted"
                                        ? _c(
                                            "h2",
                                            { staticClass: "damageStatusText" },
                                            [_vm._v("Schade goedgekeurd")]
                                          )
                                        : _vm._e(),
                                      _vm.currentDamage.status === "changed"
                                        ? _c(
                                            "h2",
                                            { staticClass: "damageStatusText" },
                                            [_vm._v("Schade aangepast")]
                                          )
                                        : _vm._e(),
                                      _vm.currentDamage.status === "rejected"
                                        ? _c(
                                            "h2",
                                            { staticClass: "damageStatusText" },
                                            [_vm._v("Schade afgekeurd")]
                                          )
                                        : _vm._e(),
                                      _vm.currentDamage.reject_reasons &&
                                      _vm.currentDamage.reject_reasons.length
                                        ? _c(
                                            "div",
                                            { staticClass: "damageStatusInfo" },
                                            [
                                              _c("span", [
                                                _vm._v("Afkeurreden(en):")
                                              ]),
                                              _c(
                                                "ul",
                                                _vm._l(
                                                  _vm.currentDamage
                                                    .reject_reasons,
                                                  function(reason, index) {
                                                    return _c(
                                                      "li",
                                                      { key: index },
                                                      [
                                                        _vm._v(
                                                          _vm._s(reason.name)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.currentDamage.reject_comment &&
                                      _vm.currentDamage.reject_comment.length
                                        ? _c(
                                            "div",
                                            { staticClass: "damageStatusInfo" },
                                            [
                                              _c("span", [
                                                _vm._v("Toelichting:")
                                              ]),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.currentDamage
                                                      .reject_comment
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.currentDamage
                                ? _c(
                                    "v-tabs",
                                    {
                                      attrs: { "slider-color": "secondary" },
                                      model: {
                                        value: _vm.activeTabDamage,
                                        callback: function($$v) {
                                          _vm.activeTabDamage = $$v
                                        },
                                        expression: "activeTabDamage"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-tab",
                                        {
                                          staticClass: "tab__filled",
                                          class: {
                                            active:
                                              _vm.activeTabDamage ===
                                              "tab-images"
                                          },
                                          attrs: { href: "#tab-images" }
                                        },
                                        [_vm._v(" Foto's ")]
                                      ),
                                      _c(
                                        "v-tab",
                                        {
                                          staticClass: "tab__filled",
                                          class: {
                                            active:
                                              _vm.activeTabDamage ===
                                              "tab-vragenlijst"
                                          },
                                          attrs: { href: "#tab-vragenlijst" }
                                        },
                                        [_vm._v(" Vragenlijst ")]
                                      ),
                                      _vm.user.type !== "simple"
                                        ? _c(
                                            "v-tab",
                                            {
                                              staticClass: "tab__filled",
                                              class: {
                                                active:
                                                  _vm.activeTabDamage ===
                                                  "tab-repairs"
                                              },
                                              attrs: { href: "#tab-repairs" }
                                            },
                                            [_vm._v(" Reparaties ")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.currentDamage
                                ? _c(
                                    "v-layout",
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", "pa-3": "" } },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "fadedText" },
                                            [
                                              _vm._v(
                                                "Klik op een schade uit de lijst aan de linkerkant"
                                              )
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "fadedText" },
                                            [_vm._v("Geen schade gekozen")]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-tabs-items",
                                {
                                  model: {
                                    value: _vm.activeTabDamage,
                                    callback: function($$v) {
                                      _vm.activeTabDamage = $$v
                                    },
                                    expression: "activeTabDamage"
                                  }
                                },
                                [
                                  _c(
                                    "v-tab-item",
                                    {
                                      attrs: {
                                        lazy: true,
                                        value: "tab-images",
                                        transition: false,
                                        "reverse-transition": false
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementPanel elementPanel__sub"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "damageContainer__content"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "damageContainer__content__inner"
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      attrs: {
                                                        wrap: "",
                                                        "mb-2": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          attrs: {
                                                            xs12: "",
                                                            "text-xs-right": ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "MiButton",
                                                            {
                                                              attrs: {
                                                                icon: "add",
                                                                small: "true",
                                                                color:
                                                                  "secondary"
                                                              },
                                                              nativeOn: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.isUploadingDamageFile = true
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Foto toevoegen "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm.currentDamage &&
                                                  _vm.currentDamage.media
                                                    ? [
                                                        _c("span", [
                                                          _vm._v(
                                                            " Klik op de \"+ Foto toevoegen knop\" rechtsboven om foto's aan deze schade toe te voegen. Zorg ervoor dat je een duidelijke overzichtsfoto en detailfoto's toevoegt bij iedere schade. "
                                                          )
                                                        ]),
                                                        _c(
                                                          "draggable",
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "list-group",
                                                              on: {
                                                                start: function(
                                                                  $event
                                                                ) {
                                                                  _vm.drag = true
                                                                },
                                                                end: function(
                                                                  $event
                                                                ) {
                                                                  ;(_vm.drag = false),
                                                                    _vm.updateDamageFileOrder()
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.currentDamageMedia,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.currentDamageMedia = $$v
                                                                },
                                                                expression:
                                                                  "currentDamageMedia"
                                                              }
                                                            },
                                                            "draggable",
                                                            _vm.dragOptions,
                                                            false
                                                          ),
                                                          [
                                                            _c(
                                                              "transition-group",
                                                              _vm._l(
                                                                _vm.currentDamageMedia,
                                                                function(
                                                                  media
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key:
                                                                        media.uuid,
                                                                      staticClass:
                                                                        "mediaContainer list-group-item"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "mediaContainer__actions"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              attrs: {
                                                                                href:
                                                                                  media.original,
                                                                                target:
                                                                                  "_blank"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "action"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "remove_red_eye"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "action",
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.editDamageFile(
                                                                                    media
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "edit"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "action",
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.deleteDamageFile(
                                                                                    media
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "delete"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "delete"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs: {
                                                                            src:
                                                                              media.thumb
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    : _vm._e(),
                                                  _vm.currentDamage &&
                                                  _vm.currentDamage.media &&
                                                  _vm.currentDamage.media
                                                    .length === 0
                                                    ? [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Geen foto's"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    : _vm._e(),
                                                  _vm.currentDamage
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "informationTooltip",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.openInformationDialog(
                                                                "mediaCheck"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  "primary",
                                                                                dark:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              "info"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                false,
                                                                2703458109
                                                              ),
                                                              model: {
                                                                value:
                                                                  _vm.mediaCheckTooltip,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.mediaCheckTooltip = $$v
                                                                },
                                                                expression:
                                                                  "mediaCheckTooltip"
                                                              }
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Bekijk de foto's"
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-tab-item",
                                    {
                                      attrs: {
                                        lazy: true,
                                        value: "tab-vragenlijst",
                                        transition: false,
                                        "reverse-transition": false
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementPanel elementPanel__sub"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "damageContainer__content"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "damageContainer__content__inner"
                                                },
                                                [
                                                  _vm.isLoading
                                                    ? _c("LoaderCard", {
                                                        attrs: {
                                                          flat: "",
                                                          type:
                                                            "spinner--center"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  !_vm.isLoading
                                                    ? [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "informationTooltip",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openInformationDialog(
                                                                  "description"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  color:
                                                                                    "primary",
                                                                                  dark:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                "info"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  2703458109
                                                                ),
                                                                model: {
                                                                  value:
                                                                    _vm.mediaTooltip,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.mediaTooltip = $$v
                                                                  },
                                                                  expression:
                                                                    "mediaTooltip"
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Voeg een korte omschrijving toe"
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm.reportId
                                                          ? _c("DynamicForm", {
                                                              attrs: {
                                                                reportId:
                                                                  _vm.reportId,
                                                                formTypes: [
                                                                  "expert"
                                                                ],
                                                                damage:
                                                                  _vm.currentDamage,
                                                                sections:
                                                                  _vm.formSections
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ]
                                                    : _vm._e()
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-tab-item",
                                    {
                                      attrs: {
                                        lazy: true,
                                        value: "tab-repairs",
                                        transition: false,
                                        "reverse-transition": false
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementPanel elementPanel__sub"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "damageContainer__content"
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    wrap: "",
                                                    "mb-2": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        "text-xs-right": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "MiButton",
                                                        {
                                                          attrs: {
                                                            icon: "add",
                                                            small: "true",
                                                            color: "secondary"
                                                          },
                                                          nativeOn: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.isCreatingRepair = true
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Reparatie toevoegen "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm.currentDamage &&
                                              _vm.currentDamage.repairs &&
                                              _vm.currentDamage.repairs.length >
                                                0
                                                ? _c(
                                                    "v-layout",
                                                    {
                                                      staticClass: "item__list",
                                                      attrs: { wrap: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass: "label",
                                                          attrs: { xs12: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                wrap: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs4: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Reparaties"
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs2: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Eenheid"
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "text-xs-right",
                                                                  attrs: {
                                                                    xs2: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Prijs"
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c("v-flex", {
                                                                attrs: {
                                                                  xs3: ""
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "damageContainer__content__inner"
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "draggable",
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "list-group",
                                                                    attrs: {
                                                                      options: {
                                                                        delay: 500
                                                                      }
                                                                    },
                                                                    on: {
                                                                      start: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.drag = true
                                                                      },
                                                                      end: function(
                                                                        $event
                                                                      ) {
                                                                        ;(_vm.drag = false),
                                                                          _vm.updateDamageRepairOrder()
                                                                      }
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.currentDamageRepairs,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.currentDamageRepairs = $$v
                                                                      },
                                                                      expression:
                                                                        "currentDamageRepairs"
                                                                    }
                                                                  },
                                                                  "draggable",
                                                                  _vm.dragOptions,
                                                                  false
                                                                ),
                                                                [
                                                                  _c(
                                                                    "transition-group",
                                                                    _vm._l(
                                                                      _vm.currentDamageRepairs,
                                                                      function(
                                                                        repair
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key:
                                                                              repair.uuid,
                                                                            staticClass:
                                                                              "list-group-item list__item"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-layout",
                                                                              {
                                                                                attrs: {
                                                                                  wrap:
                                                                                    "",
                                                                                  "align-center":
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-flex",
                                                                                  {
                                                                                    attrs: {
                                                                                      xs4:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          repair.form !==
                                                                                            "custom"
                                                                                            ? repair
                                                                                                .type
                                                                                                .name
                                                                                            : repair.custom_name
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-flex",
                                                                                  {
                                                                                    attrs: {
                                                                                      xs2:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          repair.form !==
                                                                                            "custom"
                                                                                            ? repair.quantity
                                                                                            : repair.custom_unit
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-flex",
                                                                                  {
                                                                                    staticClass:
                                                                                      "text-xs-right",
                                                                                    attrs: {
                                                                                      xs2:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm._f(
                                                                                            "currency"
                                                                                          )(
                                                                                            repair.total_incl_vat
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-flex",
                                                                                  {
                                                                                    staticClass:
                                                                                      "text-xs-center customRepairIndicator",
                                                                                    attrs: {
                                                                                      xs1:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-tooltip",
                                                                                      {
                                                                                        attrs: {
                                                                                          bottom:
                                                                                            ""
                                                                                        },
                                                                                        scopedSlots: _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key:
                                                                                                "activator",
                                                                                              fn: function(
                                                                                                ref
                                                                                              ) {
                                                                                                var on =
                                                                                                  ref.on
                                                                                                return [
                                                                                                  repair.form ===
                                                                                                  "custom"
                                                                                                    ? _c(
                                                                                                        "v-icon",
                                                                                                        _vm._g(
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              color:
                                                                                                                "primary"
                                                                                                            }
                                                                                                          },
                                                                                                          on
                                                                                                        ),
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " info "
                                                                                                          )
                                                                                                        ]
                                                                                                      )
                                                                                                    : _vm._e()
                                                                                                ]
                                                                                              }
                                                                                            }
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        )
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Aangepaste calculatie"
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "v-flex",
                                                                                  {
                                                                                    staticClass:
                                                                                      "text-xs-right",
                                                                                    attrs: {
                                                                                      xs2:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "inline-block"
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-tooltip",
                                                                                          {
                                                                                            attrs: {
                                                                                              bottom:
                                                                                                ""
                                                                                            },
                                                                                            scopedSlots: _vm._u(
                                                                                              [
                                                                                                {
                                                                                                  key:
                                                                                                    "activator",
                                                                                                  fn: function(
                                                                                                    ref
                                                                                                  ) {
                                                                                                    var on =
                                                                                                      ref.on
                                                                                                    return [
                                                                                                      _c(
                                                                                                        "span",
                                                                                                        _vm._g(
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "item__icon item__icon--action",
                                                                                                            on: {
                                                                                                              click: function(
                                                                                                                $event
                                                                                                              ) {
                                                                                                                return _vm.editRepair(
                                                                                                                  repair
                                                                                                                )
                                                                                                              }
                                                                                                            }
                                                                                                          },
                                                                                                          on
                                                                                                        ),
                                                                                                        [
                                                                                                          _c(
                                                                                                            "v-icon",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "edit"
                                                                                                              )
                                                                                                            ]
                                                                                                          )
                                                                                                        ],
                                                                                                        1
                                                                                                      )
                                                                                                    ]
                                                                                                  }
                                                                                                }
                                                                                              ],
                                                                                              null,
                                                                                              true
                                                                                            )
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Pas reparatie aan"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "inline-block"
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-tooltip",
                                                                                          {
                                                                                            attrs: {
                                                                                              bottom:
                                                                                                ""
                                                                                            },
                                                                                            scopedSlots: _vm._u(
                                                                                              [
                                                                                                {
                                                                                                  key:
                                                                                                    "activator",
                                                                                                  fn: function(
                                                                                                    ref
                                                                                                  ) {
                                                                                                    var on =
                                                                                                      ref.on
                                                                                                    return [
                                                                                                      _c(
                                                                                                        "span",
                                                                                                        _vm._g(
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "item__icon item__icon--action delete",
                                                                                                            on: {
                                                                                                              click: function(
                                                                                                                $event
                                                                                                              ) {
                                                                                                                return _vm.deleteRepair(
                                                                                                                  repair
                                                                                                                )
                                                                                                              }
                                                                                                            }
                                                                                                          },
                                                                                                          on
                                                                                                        ),
                                                                                                        [
                                                                                                          _c(
                                                                                                            "v-icon",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "delete"
                                                                                                              )
                                                                                                            ]
                                                                                                          )
                                                                                                        ],
                                                                                                        1
                                                                                                      )
                                                                                                    ]
                                                                                                  }
                                                                                                }
                                                                                              ],
                                                                                              null,
                                                                                              true
                                                                                            )
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Verwijder reparatie"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "list__item",
                                                          attrs: { xs12: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                wrap: "",
                                                                "align-center":
                                                                  ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "strongText",
                                                                  attrs: {
                                                                    xs4: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Totaal"
                                                                  )
                                                                ]
                                                              ),
                                                              _c("v-flex", {
                                                                attrs: {
                                                                  xs2: ""
                                                                }
                                                              }),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "strongText text-xs-right",
                                                                  attrs: {
                                                                    xs2: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "currency"
                                                                        )(
                                                                          _vm
                                                                            .currentDamage
                                                                            .totalCost
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              ),
                                                              _c("v-flex", {
                                                                attrs: {
                                                                  xs3: ""
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.currentDamage &&
                                              _vm.currentDamage.repairs &&
                                              _vm.currentDamage.repairs
                                                .length === 0
                                                ? [
                                                    _c("span", [
                                                      _vm._v(
                                                        ' Klik op de "+ Reparatie toevoegen knop" rechtsboven om reparaties aan deze schade toe te voegen. '
                                                      )
                                                    ])
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            ),
            _c("ApprovalBar", {
              attrs: {
                backFunction: true,
                backText: _vm.user.isSimpleUser
                  ? "Ga terug naar alle schades"
                  : "Terug"
              },
              on: { back: _vm.navigateBack },
              scopedSlots: _vm._u(
                [
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "actionContainer" },
                          [
                            _vm.isSubmitted
                              ? _c("span", { staticClass: "submittedText" }, [
                                  _vm._v("Rapport is opgeslagen")
                                ])
                              : _vm._e(),
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  color: "primary",
                                  small: "true",
                                  icon: "save"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.update()
                                  }
                                }
                              },
                              [_vm._v("Opslaan")]
                            )
                          ],
                          1
                        ),
                        _vm.isMutatieWoning
                          ? _c(
                              "div",
                              { staticClass: "actionContainer" },
                              [
                                _c(
                                  "MiButton",
                                  {
                                    attrs: {
                                      color: "success",
                                      small: "true",
                                      icon: "check_circle",
                                      disabled: _vm.isDisabled
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleApproveDialog(
                                          "opname_werkvoorbereiding_done",
                                          "Versturen naar:",
                                          "approved"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" Verstuur ")]
                                )
                              ],
                              1
                            )
                          : _vm.canSendToOpnameAfgerond
                          ? _c(
                              "div",
                              { staticClass: "actionContainer" },
                              [
                                _c(
                                  "MiButton",
                                  {
                                    attrs: {
                                      color: "success",
                                      small: "true",
                                      icon: "check_circle",
                                      disabled: _vm.isDisabled
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleApproveDialog(
                                          "opname_done",
                                          "Versturen naar:",
                                          "approved"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" Verstuur ")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                4106532242
              )
            }),
            _vm.approvalDialogOpen
              ? _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "500" },
                    model: {
                      value: _vm.approvalDialogOpen,
                      callback: function($$v) {
                        _vm.approvalDialogOpen = $$v
                      },
                      expression: "approvalDialogOpen"
                    }
                  },
                  [
                    _c("ApprovalDialog", {
                      attrs: {
                        report: _vm.reportModel,
                        title: _vm.title,
                        information:
                          "Voordat u op ‘goedkeuren’ klikt moet u zeker weten dat u alle schades heeft toegevoegd. Nadat u op ‘goedkeuren’ heeft geklikt, kunt u geen aanpassingen meer doen. Het dossier ligt dan bij de deskundige.",
                        status: _vm.status,
                        dateTime: _vm.reportModel.planned_at
                      },
                      model: {
                        value: _vm.approvalDialogOpen,
                        callback: function($$v) {
                          _vm.approvalDialogOpen = $$v
                        },
                        expression: "approvalDialogOpen"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.isUploadingDamageFile
              ? _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "750" },
                    model: {
                      value: _vm.isUploadingDamageFile,
                      callback: function($$v) {
                        _vm.isUploadingDamageFile = $$v
                      },
                      expression: "isUploadingDamageFile"
                    }
                  },
                  [
                    _c("v-card", [
                      _c(
                        "div",
                        [
                          _c(
                            "v-card-title",
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "elementPanel__header",
                                  attrs: { row: "", wrap: "" }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "header__title",
                                      attrs: { sm11: "" }
                                    },
                                    [_c("h2", [_vm._v("Media toevoegen")])]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "header__close",
                                      attrs: { sm1: "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.closeUploadingDamageFileDialog(
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("cancel")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-container",
                                { staticClass: "createDialog" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              staticClass:
                                                "fileUpload__container",
                                              attrs: {
                                                "fill-height": "",
                                                "pa-1": "",
                                                wrap: ""
                                              }
                                            },
                                            [
                                              _c("MiFileUpload", {
                                                ref: "fileUpload",
                                                attrs: {
                                                  filePath:
                                                    _vm.generalUploadedFilePath,
                                                  clearAfterUpload: false,
                                                  acceptedFileTypes:
                                                    _vm.acceptedFileTypes,
                                                  multiple: true
                                                },
                                                on: {
                                                  itemDropped: function(
                                                    $event
                                                  ) {
                                                    return _vm.handleDamageFileDropped(
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm.isCreatingRepair
              ? _c(
                  "v-dialog",
                  {
                    attrs: { persistent: true, "max-width": "700px" },
                    model: {
                      value: _vm.isCreatingRepair,
                      callback: function($$v) {
                        _vm.isCreatingRepair = $$v
                      },
                      expression: "isCreatingRepair"
                    }
                  },
                  [
                    _c("RepairDialog", {
                      attrs: {
                        report: _vm.reportModel,
                        damage: _vm.currentDamage,
                        repair: _vm.repair
                      },
                      on: { closeDialog: _vm.closeCreatingRepair }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm.currentInformation
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: true, "max-width": "700px" },
              model: {
                value: _vm.informationDialogOpen,
                callback: function($$v) {
                  _vm.informationDialogOpen = $$v
                },
                expression: "informationDialogOpen"
              }
            },
            [
              _c(
                "v-card",
                [
                  _vm.isLoading
                    ? _c("LoaderCard", {
                        attrs: { type: "spinner--center", minHeight: "400px" }
                      })
                    : _vm._e(),
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "elementPanel__header",
                          attrs: { row: "", wrap: "" }
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "header__title",
                              attrs: { sm11: "" }
                            },
                            [
                              _c(
                                "h2",
                                [
                                  _c("v-icon", { staticClass: "infoIcon" }, [
                                    _vm._v("info")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.informationContent[
                                          _vm.currentInformation
                                        ].title
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "header__close",
                              attrs: { sm1: "" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      _vm.informationDialogOpen = false
                                    }
                                  }
                                },
                                [_vm._v("cancel")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(
                    _vm.informationContent[_vm.currentInformation].content,
                    function(information) {
                      return _c("div", { key: information.title }, [
                        information.header
                          ? _c("h2", { staticClass: "informationTitle" }, [
                              _vm._v(_vm._s(information.header))
                            ])
                          : _vm._e(),
                        information.text
                          ? _c("p", [_vm._v(_vm._s(information.text))])
                          : _vm._e(),
                        information.bulletPoints
                          ? _c(
                              "ul",
                              { staticClass: "bulletPointList" },
                              _vm._l(information.bulletPoints, function(
                                bulletPoint
                              ) {
                                return _c("li", { key: bulletPoint }, [
                                  _vm._v(_vm._s(bulletPoint))
                                ])
                              }),
                              0
                            )
                          : _vm._e()
                      ])
                    }
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isEditingDamageFile
        ? _c(
            "v-dialog",
            {
              attrs: { fullscreen: true },
              model: {
                value: _vm.isEditingDamageFile,
                callback: function($$v) {
                  _vm.isEditingDamageFile = $$v
                },
                expression: "isEditingDamageFile"
              }
            },
            [
              _c("EditImageDialog", {
                attrs: { selectedMedia: _vm.selectedDamageFile },
                on: {
                  closeDialog: _vm.closeEditingDamageFileDialog,
                  postFile: function($event) {
                    return _vm.postFile($event, true)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isUploadingReportFile
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "750" },
              model: {
                value: _vm.isUploadingReportFile,
                callback: function($$v) {
                  _vm.isUploadingReportFile = $$v
                },
                expression: "isUploadingReportFile"
              }
            },
            [
              _c("v-card", [
                _c(
                  "div",
                  [
                    _c(
                      "v-card-title",
                      [
                        _c(
                          "v-layout",
                          {
                            staticClass: "elementPanel__header",
                            attrs: { row: "", wrap: "" }
                          },
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass: "header__title",
                                attrs: { sm11: "" }
                              },
                              [
                                _vm.reportFileType === "attachment"
                                  ? _c("h2", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isEditingReportFile
                                              ? "Bijlage aanpassen"
                                              : "Bijlage toevoegen"
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.reportFileType === "perspective"
                                  ? _c("h2", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isEditingReportFile
                                              ? "Aanzichtfoto aanpassen"
                                              : "Aanzichtfoto toevoegen"
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.reportFileType === "maps"
                                  ? _c("h2", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isEditingReportFile
                                              ? "Plattegronden aanpassen"
                                              : "Plattegronden toevoegen"
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "header__close",
                                attrs: { sm1: "" }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.closeReportFileDialog($event)
                                      }
                                    }
                                  },
                                  [_vm._v("cancel")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.isLoading
                      ? _c(
                          "v-card-text",
                          [
                            _vm.isLoading
                              ? _c("LoaderCard", {
                                  attrs: {
                                    flat: "",
                                    type: "spinner--center",
                                    minHeight: "300px"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isLoading
                      ? _c(
                          "v-card-text",
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c(
                                  "v-flex",
                                  { staticClass: "field", attrs: { xs12: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "omschrijving",
                                        "hide-details": true
                                      },
                                      model: {
                                        value: _vm.reportFileDescription,
                                        callback: function($$v) {
                                          _vm.reportFileDescription = $$v
                                        },
                                        expression: "reportFileDescription"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            !_vm.isEditingReportFile
                              ? _c(
                                  "v-layout",
                                  {
                                    staticClass: "fileUpload__container",
                                    attrs: {
                                      "fill-height": "",
                                      "mb-3": "",
                                      "pa-1": "",
                                      wrap: ""
                                    }
                                  },
                                  [
                                    _c("MiFileUpload", {
                                      ref: "fileUpload",
                                      attrs: {
                                        filePath: _vm.generalUploadedFilePath,
                                        clearAfterUpload: false,
                                        multiple: false
                                      },
                                      on: {
                                        itemDropped: function($event) {
                                          return _vm.handleReportFileDropped(
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isLoading
                      ? _c(
                          "v-card-actions",
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: { "justify-space-between": "", row: "" }
                              },
                              [
                                _c(
                                  "MiButton",
                                  {
                                    attrs: {
                                      color: "text-light",
                                      outline: "true"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.closeReportFileDialog($event)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("actions.cancel")) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "MiButton",
                                  {
                                    attrs: { color: "success", icon: "save" },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.saveReportFile($event)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("actions.save")))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }