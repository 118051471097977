import { Component, Vue, Watch } from 'vue-property-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { formatDate, isValidDate, setFormattedDatePickerValue, dateErrorMessage } from '@/support/String';
import { Options } from '@/components/mi-dialog/MiDialog';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload';
import { Report as ReportModel, Media, Answer } from '@/models/Report';
import { Repair } from '@/models/Repair';
import { Damage } from '@/models/Damage';
import { File as FileModel } from '@/models/File';
import draggable from 'vuedraggable';
import { User } from '@/models/User';
import { cloneDeep, debounce } from 'lodash';
import { FileType } from '@/views/Reports/Report';
import { DateTime } from 'luxon';
import { Application } from '@/support/Application';
import { DateFormatter } from '@/support/DateFormatter';
import { GeneralKey, GeneralKeys } from '@/support/GeneralKeys';

@Component<SimpleEditor>({
  components: {
    draggable,
  },
})
export default class SimpleEditor extends Vue {
  public $pageTitle = 'Deskundige eenvoudig';

  protected reportModel: ReportModel = new ReportModel();

  protected reportClone: ReportModel | null = null;

  protected googleMaps = '';

  protected currentDamage: Damage | null = null;

  protected currentDamageRepairs: Repair[] | null = null;

  protected currentDamageMedia: FileModel[] | null = null;

  protected currentSelectedDamage: Damage | null = null;

  protected damages: Damage[] = [];

  protected reportId = '';

  protected isLoading = true;

  protected fileCounter = 0;

  protected uploadQueue: File[] = [];

  protected postTimeOut: any | null = null;

  // edit mode
  protected isEditingReport = false;

  protected isUpdatingReport = false;

  protected isSearchingExpert = false;

  protected isSaved = false;

  protected isEditingPlannedAt = false;

  protected planned_at_date = '';

  protected planned_at_time = '';

  protected plannedAt = new DateFormatter();

  protected databaseDate = '0000-00-00 00:00:00';

  protected expertSearch = '';

  protected currentExpert = '';

  protected experts: User[] = [];

  protected expertNames: string[] = [];

  // report files
  protected activeTabReport = 'tab-bijlage';

  protected perspectivePictures: Media[] = [];

  protected mapPictures: Media[] = [];

  protected attachments: Media[] = [];

  protected selectedReportFile: Media | null = null;

  protected isEditingReportFile = false;

  protected isUploadingReportFile = false;

  protected reportFileType = '';

  protected selectedReportFileType = 'offerte';

  protected reportFileDescription = '';

  protected showFileInReport = '0';

  protected reportFileTypesArray: FileType[] = [
    {
      label: 'Offerte',
      value: 'offerte',
    },
    {
      label: 'Addendum',
      value: 'addendum',
    },
    {
      label: 'Nader onderzoek',
      value: 'nader_onderzoek',
    },
  ];

  // damage files
  protected activeTabDamage = 'tab-images';

  protected selectedDamageFile: Media | null = null;

  protected isUploadingDamageFile = false;

  protected isEditingDamageFile = false;

  // Upload image component
  protected generalSelectedFile: File | Media | null = null;

  protected generalUploadedFilePath = '';

  protected acceptedFileTypes: string[] = [
    'image/png',
    'image/jpeg',
    'image/svg+xml',
    'pdf',
  ];

  protected debouncedSearch: Function = debounce(this.fetchExperts, 300);

  protected userFilter: UserFilter = {};

  // tooltip models
  protected informationDialogOpen = false;

  protected currentInformation = '';

  protected mediaTooltip = false;

  protected mediaCheckTooltip = false;

  protected informationContent: any = {
    media: {
      title: "Informatie: Het maken van foto's",
      content: [{
        header: "1. Aanzichtfoto's",
        text: 'Begin altijd met een aanzichtsfoto met de gehele woning, waarop ook de voordeur en huisnummer te zien zijn.',
      }, {
        header: '2. Exterieur',
        text: 'Loop met de klok mee om de woning heen. Voeg van alle schades die die aan de buitenkant van de woning bevinden de volgende 3 type foto’s toe.',
        bulletPoints: [
          'Overzichtsfoto(‘s) van de gevel(s) waar schade is geconstateerd met een markering van locatie met schade(s)',
          'overzichtsfoto(‘s) van de schade(s) met markering van locatie met schade(s)',
          'detailfoto(‘s) van de schade(s)',
        ],
      }, {
        header: '3. Interieur',
        text: 'Bij het interieur start u op de hoogste verdieping die met een vaste trap (inclusief vlizotrap) bereikbaar is en werkt vervolgens naar de begane grond (of kelder, mits begaanbaar).',
        bulletPoints: [
          'overzichtsfoto(‘s) van de ruimte(n)',
          'overzichtsfoto(‘s) van de schade(s)',
          'detailfoto(‘s) van de schade(s)',
        ],
      }, {
        text: 'Let op; zorg dat er geen personen, autokentekens of leesbare naambordjes op de foto staan.',
      }],
    },
    mediaCheck: {
      title: "Controleer de foto's",
      content: [{
        text: 'Weet u zeker dat alle foto’s toegevoegd zijn en er geen natuurlijke personen geïdentificeerd kunnen worden op basis van deze foto’s?',
      }],
    },
    description: {
      title: 'Korte beschrijving',
      content: [{
        text: 'Voeg een korte beschrijving toe met minimaal:',
        bulletPoints: [
          'Het aantal schades dat op de foto te zien is',
          'De grootte van de schade(s)',
          'Waar de schade zich bevind',
          'Het materiaal',
        ],
      }],
    },
  };

  protected formSections: any = [51];

  protected generalKeys: GeneralKey = {
    SchadeadresStraat: GeneralKeys.SchadeadresStraat,
    SchadeadresHuisnummer: GeneralKeys.SchadeadresHuisnummer,
    SchadeadresHuisnummerToevoeging: GeneralKeys.SchadeadresHuisnummerToevoeging,
    SchadeadresPlaats: GeneralKeys.SchadeadresPlaats,
    SchadeadresPostcode: GeneralKeys.SchadeadresPostcode,
    DatumAanvraag: GeneralKeys.DatumAanvraag,
    BouwJaar: GeneralKeys.BouwJaar,
    EigendomSinds: GeneralKeys.EigendomSinds,
    storyOfApplicant: GeneralKeys.storyOfApplicant,
    ExpertComment: GeneralKeys.ExpertComment,
    relevantRemarksApplicant: GeneralKeys.relevantRemarksApplicant,
  };

  // Repairs
  protected selectedRepair: Repair | null = null;

  protected repair: Repair = new Repair();

  protected isCreatingRepair = false;

  protected sections: string[] = ['1', '2', '3', '4', '5', '6', '7', '8'];

  protected mask: Mask = {
    time: '##:##',
  }

  protected isAllowedToView = false;

  protected isAllowedToViewDialog = false;

  // approval bar
  protected approvalDialogOpen = false;

  protected status = '';

  protected planned_at = '';

  protected approval = '';

  protected title = '';

  protected isValidDate = true;

  protected isSubmitted = false;

  public mounted() {
    this.$root.$on('dateFieldValid', this.setValidDate);
    this.repair.form = 'calculation';
    this.reportId = this.$route.params.id;
    this.initialize();
  }

  protected initialize() {
    this.isLoading = true;

    this.reportModel
      .include(['applicant', 'damages', 'answers', 'attachments', 'media', 'reject_reasons'])
      .find(this.$route.params.id)
      .then((report: ReportModel) => {
        this.reportModel = report;
        this.damages = [];
        this.damages = (report.damages as Damage[]).sort(this.compare);
        this.isLoading = false;
        // if (this.reportModel.planned_at && this.reportModel.planned_at !== '0000-00-00 00:00:00') {
        //   this.planned_at_date = formatDate(this.reportModel.planned_at, 'yyyy-LL-dd');
        //   this.planned_at_time = formatDate(this.reportModel.planned_at, 'HH:mm');
        // }

        this.plannedAt.selectedDate = this.reportModel.planned_at && this.reportModel.planned_at !== this.databaseDate
          ? this.reportModel.planned_at
          : '';

        this.plannedAt.selectedTime = this.reportModel.planned_at && this.reportModel.planned_at !== this.databaseDate
          ? formatDate(this.reportModel.planned_at, 'HH:mm')
          : '';

        if (this.reportModel.expert) {
          this.currentExpert = this.reportModel.expert.uuid ? this.reportModel.expert.uuid : '';
        }

        if (! this.$store.state.Auth.external_user) {
          this.initBreadcrumb();
        }

        this.setGeneralInfo();
        this.initGoogleMaps();
        this.fetchExperts();
        this.filterMedia();

        this.isAllowedToViewDialog = true;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected initBreadcrumb() {
    if (this.$store.state.Auth === 'simple' || this.$store.state.Auth === 'simple_plus') {
      this.$root.$emit('breadcrumbUpdated',
        {
          crumb: [
            { name: 'Alle rapporten', path: '/reports' },
            { name: this.reportModel.case_number },
            { name: 'Deskundige eenvoudig' },
          ],
        });
    } else {
      this.$root.$emit('breadcrumbUpdated',
        {
          crumb: [
            { name: 'Alle rapporten', path: '/reports' },
            { name: this.reportModel.case_number, path: `/reports/${this.reportModel.uuid}` },
            { name: 'Deskundige eenvoudig' },
          ],
        });
    }
  }

  protected hasRejectedInfo() {
    return (this.reportModel.reject_reasons && this.reportModel.reject_reasons.length)
            || (this.reportModel.reject_comment && this.reportModel.reject_comment.length);
  }

  // PLANNED AT DATE
  protected plannedAtDateFormatted: string | null = null;

  protected plannedAtDateErrorMessage = '';

  protected formatPlannedAtDateFromDatePicker() {
    if (this.planned_at_date) {
      this.plannedAtDateFormatted = setFormattedDatePickerValue(this.planned_at_date, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.plannedAtDateErrorMessage = ! isValidDate(this.plannedAtDateFormatted) ? dateErrorMessage : '';
    }
  }

  protected formatPlannedAtDateFromTextField(value: string) {
    this.plannedAtDateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.planned_at_date = setFormattedDatePickerValue(value);
  }

  @Watch('planned_at_date')
  protected planned_at_dateChanged() {
    this.formatPlannedAtDateFromDatePicker();
  }

  @Watch('isEditingPlannedAt')
  protected isEditingPlannedAtChanged() {
    if (! this.isEditingPlannedAt) {
      this.formatPlannedAtDateFromDatePicker();
    }
  }

  protected get isDisabledEditing(): boolean {
    if (this.plannedAt.errorMessage.length > 0 || this.isFutureDate) {
      return true;
    }

    return false;
  }

  protected get isFutureDate(): boolean {
    if (! this.plannedAt.selectedDate || ! this.plannedAt.selectedTime) {
      return true;
    }

    return DateTime.local().toFormat('yyyy-LL-dd HH:mm:ss').toString() < (this.plannedAt.selectedDate as string);
  }

  protected initGoogleMaps() {
    if (this.reportModel.address
      && this.reportModel.address.street
      && this.reportModel.address.number
      && this.reportModel.address.postcode
      && this.reportModel.address.city
    ) {
      this.googleMaps = encodeURI(`${this.reportModel.address.street} ${this.reportModel.address.number} ${this.reportModel.address.number_add ? this.reportModel.address.number_add : ''},  ${this.reportModel.address.postcode} ${this.reportModel.address.city}`);
    }
  }

  protected filterMedia() {
    if (! this.reportModel.media) {
      return;
    }

    this.perspectivePictures = this.reportModel.media.filter((media: Media) => media.type === 'picture');

    this.mapPictures = this.reportModel.media.filter((media: Media) => media.type === 'floorplan');

    this.attachments = this.reportModel.media.filter((media: Media) => media.type !== 'floorplan' && media.type !== 'picture');
  }

  protected allowedToView(allowed: boolean) {
    this.isAllowedToView = allowed;
  }

  private compare(a: any, b: any) {
    let comparison = 0;

    if (a.sort_order === undefined || b.sort_order === undefined) {
      return 0;
    }

    if (a.sort_order > b.sort_order) {
      comparison = 1;
    } else if (a.sort_order < b.sort_order) {
      comparison = - 1;
    }
    return comparison;
  }

  protected fetchExperts(query: any = '') {
    this.isSearchingExpert = true;

    new User()
      .filter({
        types: ['expert', 'simple', 'simple_plus', 'pre_controller', 'manager', 'admin'],
        search: query && query.target && query.target.value ? query.target.value : '',
      })
      .sort('name', 'ASC')
      .all()
      .then((response: any) => {
        this.experts = response;
        if (this.reportModel.expert) {
          this.experts.unshift((new User(this.reportModel.expert)));
        }

        this.expertNames = this.experts.map((expert: User) => (expert ? expert.first_name as string : ''));
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected setGeneralInfo() {
    const keys = Object.keys(this.generalKeys);
    if (! this.reportModel.answers) {
      return;
    }

    this.reportModel.answers.forEach((anwser: Answer) => {
      if (keys.includes(anwser.key ? anwser.key : '')) {
        (this.generalKeys as any)[anwser.key ? anwser.key : ''].value = anwser.value;
      }
    });
  }

  protected openInformationDialog(informationType: string) {
    this.currentInformation = informationType;
    this.informationDialogOpen = true;
  }

  protected previewPdf() {
    window.open(this.reportModel.links.preview);
  }

  protected get dragOptions() {
    return {
      animation: 200,
      disabled: false,
      ghostClass: 'ghost',
    };
  }

  get user(): User {
    return this.$store.state.Auth;
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    //
  }

  @Watch('expertSearch')
  protected expertSearchChanged(query: string) {
    if (query && query.length) {
      this.userFilter.search = query;
      this.debouncedSearch(query);
    }
  }

  /*  */
  /*  Edit this report  */
  /*  */
  protected updateReport() {
    this.isUpdatingReport = true;
    const payload: {[key: string]: any} = {
      expert: this.currentExpert,
    };

    payload.planned_at = this.plannedAt.selectedDate ? DateTime.fromSQL(`${this.plannedAt.selectedDate} ${this.plannedAt.selectedTime}`).toFormat('yyyy-LL-dd HH:mm:ss') : '';

    this.reportModel
      .update(payload)
      .then(() => {
        this.isSaved = true;
        this.initialize();
        this.clearSaved();
      })
      .catch((error: AxiosError) => {
        this.clearSaved();
        ErrorHandler.network(error);
      });
  }

  protected resetReport() {
    if (! this.reportClone) {
      return;
    }

    this.reportModel = cloneDeep(this.reportClone);
    if (this.reportModel.planned_at) {
      this.planned_at_date = this.reportModel.planned_at;
      this.planned_at_time = formatDate(this.reportModel.planned_at, 'HH:mm');
    }

    if (this.reportModel.expert) {
      this.currentExpert = this.reportModel.expert.uuid ? this.reportModel.expert.uuid : '';
    }
    this.reportClone = null;
  }

  @Watch('isEditingReport')
  protected reportChanged(isEditing: boolean) {
    if (isEditing) {
      if (this.reportModel.planned_at) {
        this.planned_at_date = formatDate(this.reportModel.planned_at, 'yyyy-LL-dd');
        this.planned_at_time = formatDate(this.reportModel.planned_at, 'HH:mm');
      }
      this.reportClone = cloneDeep(this.reportModel);
    }
  }

  private clearSaved() {
    setTimeout(() => {
      this.isUpdatingReport = false;
      this.isSaved = false;
      this.isEditingReport = false;
    }, 1200);
  }

  /*  */
  /*  Add/Edit/Remove report files */
  /*  */
  protected openReportFileDialog(type: string) {
    this.reportFileType = type;
    this.isUploadingReportFile = true;
    this.isEditingReportFile = false;
  }

  protected closeReportFileDialog() {
    this.isUploadingReportFile = false;

    this.reportFileDescription = '';
    this.selectedReportFileType = 'offerte';
    this.showFileInReport = '1';

    this.generalUploadedFilePath = '';
    this.generalSelectedFile = null;
  }

  protected handleReportFileDropped(file: File) {
    if (! file) {
      return;
    }

    this.generalSelectedFile = file;
    const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
    fileUploadComponent.uploaded();
  }

  protected saveReportFile() {
    if (! this.isEditingReportFile) {
      this.createReportFile();
      return;
    }

    this.updateReportFile();
  }

  protected createReportFile() {
    this.isLoading = true;

    let payload = {
      description: this.reportFileDescription,
      file: this.generalSelectedFile,
    };

    let typePayload = {};

    if (this.reportFileType === 'perspective') {
      typePayload = {
        type: 'picture',
      };
    }

    if (this.reportFileType === 'maps') {
      typePayload = {
        type: 'floorplan',
      };
    }

    payload = { ...payload, ...typePayload };

    if (! this.generalSelectedFile) {
      this.isLoading = false;
      return;
    }

    new ReportModel()
      .mediaEndpoint(this.reportModel.uuid ? this.reportModel.uuid : '')
      .create(payload)
      .then((response: AxiosResponse) => {
        this.initialize();
        this.closeReportFileDialog();
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      });
  }

  protected updateReportFile() {
    this.isLoading = true;
    if (! this.selectedReportFile) {
      return;
    }

    let payload = {
      description: this.reportFileDescription,
      type: this.selectedReportFileType,
    };

    const typePayload = {};

    payload = { ...payload, ...typePayload };

    this.reportModel
      .updateMediaEndpoint(this.selectedReportFile.uuid ? this.selectedReportFile.uuid : '', payload)
      .then((response: AxiosResponse) => {
        this.initialize();
        this.closeReportFileDialog();
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected editReportFile(reportFile: Media) {
    this.selectedReportFile = reportFile;
    this.reportFileDescription = reportFile.description ? reportFile.description : '';
    this.selectedReportFileType = reportFile.type ? reportFile.type : '';
    this.showFileInReport = reportFile.show_in_report ? reportFile.show_in_report : '1';
    this.isUploadingReportFile = true;
    this.isEditingReportFile = true;

    if (this.activeTabReport === 'tab-bijlage') {
      this.reportFileType = 'attachment';
    }

    if (this.activeTabReport === 'tab-aanzichtfotos') {
      this.reportFileType = 'perspective';
    }

    if (this.activeTabReport === 'tab-plattegronden') {
      this.reportFileType = 'maps';
    }
  }

  protected deleteReportFile(reportFile: Media) {
    this.selectedReportFile = reportFile;
    this.$store.dispatch('openDialog', this.dialogOptionsDeleteReporFile);
  }

  protected get dialogOptionsDeleteReporFile(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je dit bestand wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          action: () => {
            if (! this.reportModel || ! this.selectedReportFile) {
              return;
            }

            this.reportModel
              .deleteMediaEndpoint(this.selectedReportFile.uuid ? this.selectedReportFile.uuid : '')
              .then((response: AxiosResponse) => {
                this.initialize();
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedReportFile = null;
          },
        },
      },
    };
  }

  protected updateReportFileOrder(key: string) {
    let fileIds: string[];
    switch (key) {
      case 'mapPictures':
        fileIds = this.mapPictures.map((file: Media) => file.uuid as string);
        break;
      case 'perspectivePictures':
        fileIds = this.perspectivePictures.map((file: Media) => file.uuid as string);
        break;

      default:
        fileIds = [];
        break;
    }

    if (! fileIds.length) {
      return;
    }

    new FileModel()
      .sortFiles(fileIds)
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  /*  */
  /*  Edit/Remove repair  */
  /*  */
  protected editRepair(repair: Repair) {
    this.repair = repair;
    this.isCreatingRepair = true;
  }

  protected deleteRepair(repair: Repair) {
    this.selectedRepair = repair;
    this.$store.dispatch('openDialog', this.dialogOptionsDeleteRepair);
  }

  protected get dialogOptionsDeleteRepair(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze reparatie wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          color: 'warning',
          action: () => {
            if (! this.currentDamage || ! this.selectedRepair) {
              return;
            }

            new Repair()
              .removeRepair((this.selectedRepair as any).uuid)
              .then((response: AxiosResponse) => {
                if (! this.currentDamage) {
                  return;
                }
                this.getDamage(this.currentDamage);
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.currentSelectedDamage = null;
          },
        },
      },
    };
  }

  protected getDamages() {
    if (this.currentDamage && this.currentDamage.status === 'changed') {
      return;
    }

    this.reportModel
      .include(['damages', 'validations', 'reject_reasons'])
      .find(this.$route.params.id)
      .then((report: ReportModel) => {
        this.damages = [];
        this.damages = (report.damages as Damage[]).sort(this.compare);

        if (this.currentDamage) {
          this.getDamage(this.currentDamage);
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected closeCreatingRepair() {
    this.repair = new Repair();
    if (this.currentDamage) {
      this.getDamage(this.currentDamage);
    }
    this.isCreatingRepair = false;
  }

  protected updateDamageRepairOrder() {
    if (! this.currentDamageRepairs) {
      return;
    }

    const repairIds: string[] = this.currentDamageRepairs.map((repair: Repair) => repair.uuid as string);

    new Repair()
      .sortRepairs(repairIds)
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  /*  */
  /*  Add/Edit/Remove damages  */
  /*  */
  protected getDamage(damage: Damage) {
    this.isLoading = true;

    new Damage()
      .find(damage.uuid)
      .then((damage: Damage) => {
        this.currentDamage = null;
        this.currentDamage = damage as Damage;
        this.currentDamageRepairs = (damage.repairs as Repair[]).sort(this.compare);
        this.currentDamageMedia = (damage.media as FileModel[]).sort(this.compare);
        this.calculateDamageTotals();
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected addDamage() {
    new Damage()
      .create({ report: this.$route.params.id })
      .then((damage: Damage) => {
        damage.name = 'Nieuwe schade';
        this.damages.push(damage);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected deleteDamage(damage: Damage) {
    this.currentSelectedDamage = damage;
    this.$store.dispatch('openDialog', this.dialogOptionsDeleteDamage);
  }

  protected get dialogOptionsDeleteDamage(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze schade wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          color: 'warning',
          action: () => {
            if (! this.currentSelectedDamage) {
              return;
            }

            new Damage(this.currentSelectedDamage)
              .delete()
              .then((response: AxiosResponse) => {
                this.initialize();
                this.currentSelectedDamage = null;
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.currentSelectedDamage = null;
          },
        },
      },
    };
  }

  protected calculateDamageTotals() {
    if (! this.currentDamage || ! this.currentDamage.repairs) {
      return;
    }
    let total = 0;
    this.currentDamage.repairs.forEach((repair: any) => {
      total += repair.total_incl_vat;
    });
    this.currentDamage.totalCost = total;
    total = 0;
  }

  protected sortDamageUp(damage: Damage, index: number) {
    new Damage(this.damages[index - 1])
      .update({ sort_order: index })
      .then((response: AxiosResponse) => {
        this.initialize();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    new Damage(damage)
      .update({ sort_order: index - 1 })
      .then((response: AxiosResponse) => {
        this.initialize();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected sortDamageDown(damage: Damage, index: number) {
    new Damage(this.damages[index + 1])
      .update({ sort_order: index })
      .then((response: AxiosResponse) => {
        this.initialize();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    new Damage(damage)
      .update({ sort_order: index + 1 })
      .then((response: AxiosResponse) => {
        this.initialize();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected updateDamageOrder() {
    if (! this.damages) {
      return;
    }

    const damageIds: string[] = this.damages.map((damage: Damage) => damage.uuid as string);

    new Damage()
      .sortDamages(damageIds)
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  /*  */
  /*  Add/Edit/Remove damage files  */
  /*  */
  protected closeEditingDamageFileDialog() {
    this.isEditingDamageFile = false;
  }

  protected closeUploadingDamageFileDialog() {
    this.isUploadingDamageFile = false;
    this.fileCounter = 0;
  }

  protected editDamageFile(damageFile: Media) {
    this.selectedDamageFile = damageFile;
    this.isEditingDamageFile = true;
  }

  protected deleteDamageFile(damageFile: Media) {
    this.selectedDamageFile = damageFile;
    this.$store.dispatch('openDialog', this.dialogOptionsDeleteDamageFile);
  }

  protected get dialogOptionsDeleteDamageFile(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze afbeelding wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          color: 'warning',
          action: () => {
            if (! this.currentDamage || ! this.selectedDamageFile) {
              return;
            }

            new Damage(this.selectedDamageFile)
              .mediaEndpoint(this.currentDamage)
              .delete()
              .then((response: AxiosResponse) => {
                if (! this.currentDamage) {
                  return;
                }
                this.getDamage(this.currentDamage);
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.currentSelectedDamage = null;
            this.selectedDamageFile = null;
          },
        },
      },
    };
  }

  protected handleDamageFileDropped(file: File) {
    if (! file) {
      return;
    }

    this.uploadQueue.push(file);
  }

  protected postDamageFile(file: File | string | string[], editing = false) {
    if (! this.currentDamage) {
      return;
    }
    new Damage()
      .mediaEndpoint(this.currentDamage)
      .create({ file })
      .then((response: AxiosResponse) => {
        if (editing) {
          this.isEditingDamageFile = false;
          this.isLoading = false;
        } else {
          const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
          if (fileUploadComponent) {
            fileUploadComponent.uploadedMultiple(this.fileCounter);
            this.fileCounter += 1;
          }
        }

        if (! this.currentDamage) {
          return;
        }
        this.getDamage(this.currentDamage);
      })
      .catch((error: AxiosError) => {
        if (editing) {
          this.isEditingDamageFile = false;
        } else {
          const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
          if (fileUploadComponent) {
            fileUploadComponent.uploadedMultipleFailed(this.fileCounter);
          }
        }
      });
  }

  protected postMultipleDamageFiles() {
    if (! this.currentDamage || ! this.uploadQueue.length) {
      return;
    }

    new Damage()
      .mediaEndpoint(this.currentDamage)
      .create({ file: this.uploadQueue[0] })
      .then((response: AxiosResponse) => {
        const queueCopy = [...[], ...this.uploadQueue];
        queueCopy.shift();
        this.uploadQueue = queueCopy;

        const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
        fileUploadComponent.uploadedMultiple(this.fileCounter);

        if (this.uploadQueue.length <= 0) {
          this.fileCounter = 0;
          this.getDamage((this.currentDamage as any));
        } else {
          this.fileCounter += 1;
          this.postMultipleDamageFiles();
        }
      })
      .catch((error: AxiosError) => {
        const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
        fileUploadComponent.uploadedMultipleFailed(this.fileCounter);
      });
  }

  protected updateDamageFileOrder() {
    if (! this.currentDamageMedia) {
      return;
    }

    const fileIds: string[] = this.currentDamageMedia.map((file: FileModel) => file.uuid as string);

    new FileModel()
      .sortFiles(fileIds)
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  @Watch('uploadQueue')
  public uploadQueueChanged(to: File[], from: File[]) {
    if (to.length < from.length) {
      return;
    }

    if (this.postTimeOut) {
      clearTimeout(this.postTimeOut);
    }

    this.postTimeOut = setTimeout(() => {
      this.postMultipleDamageFiles();
    }, 20);
  }

  /*  */
  /*  Approval bar & approval dialog  */
  /*  */
  protected toggleApproveDialog(status: string, title: string, approval: string) {
    this.status = status;
    this.approval = approval;
    this.title = title;
    this.approvalDialogOpen = true;
  }

  protected get allowSubmisionToTcmg() {
    if (this.isSimpleUser || this.reportModel.isWoningCooperatie2Report || this.isOpname) {
      return false;
    }

    return true;
  }

  protected get isOpname() {
    return this.reportModel.hasStatus('opname_in_progress');
  }

  protected get isSimpleUser() {
    return this.user.hasRole(['simple', 'simple_plus']);
  }

  protected get canSendToOpnameAfgerond() {
    if (this.user.isSimpleUser) {
      return this.reportModel.isEigenAannemerReport;
    }

    return this.isOpname;
  }

  protected get isDisabled(): boolean {
    if (! this.reportModel.planned_at) {
      return true;
    }
    return DateTime.local().toFormat('yyyy-LL-dd HH:mm:ss').toString() < this.reportModel.planned_at || ! this.isValidDate;
  }

  private setValidDate(isValid: boolean) {
    this.isValidDate = isValid;
  }

  protected update() {
    if (this.isDisabled) {
      return;
    }

    this.isLoading = true;

    new ReportModel()
      .setReportId(this.$route.params.id)
      .update()
      .then(() => {
        this.initialize();
        this.isSubmitted = true;
        setTimeout(() => {
          this.isSubmitted = false;
          this.isLoading = false;
        }, 1000);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected navigateBack() {
    if (this.user.isSimpleUser) {
      this.navigateToTcmg();
      return;
    }

    this.$router.push(`/reports/${this.reportModel.uuid}`);
  }

  protected navigateToTcmg() {
    if (Application.config('APP_ENV') === 'production') {
      window.location.href = 'https://img.schadeexpertise-groningen.nl';
    } else {
      window.location.href = 'https://img.test.schadeexpertise-groningen.nl';
    }
  }

  protected postFile(file: File | string | string[], editing = false) {
    if (! this.currentDamage) {
      return;
    }
    new Damage()
      .mediaEndpoint(this.currentDamage)
      .create({ file })
      .then((response: AxiosResponse) => {
        if (editing) {
          this.isEditingDamageFile = false;
          this.isLoading = false;
        } else {
          const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
          if (fileUploadComponent) {
            fileUploadComponent.uploadedMultiple(this.fileCounter);
            this.fileCounter += 1;
          }
        }

        if (! this.currentDamage) {
          return;
        }
        this.getDamage(this.currentDamage);
      })
      .catch((error: AxiosError) => {
        if (editing) {
          this.isEditingDamageFile = false;
        } else {
          const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
          if (fileUploadComponent) {
            fileUploadComponent.uploadedMultipleFailed(this.fileCounter);
          }
        }
      });
  }

  protected get isMutatieWoning(): boolean {
    return this.reportModel.isMutatieWoningReport;
  }
}

interface Mask {
  [key: string]: string;
}

interface UserFilter {
  search?: string;
}
